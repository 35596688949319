import React from 'react';
import ResponsiveAppBar from '../../Nav/ResponsiveNav';
import Footer from '../../Footer/Footer';

import './contact.css';

const Contact = () => {
  return (
    <div>
      <ResponsiveAppBar />
      <div className="contact" id="Contact">
        <iframe height="598" title="Embedded Wufoo Form" allowtransparency="true" frameBorder="0" scrolling="no" style={{ width:'80%', border:'none'}} src="https://tymorrison.wufoo.com/embed/w1dbgud509pa692/">  </iframe>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;