import React from 'react';
import Logo from '../../images/logo.svg';
import './home.css';

const Home = () => {
  return (
    <div class="landing">
      <img src={Logo} alt="Doggie Suds Logo" />
    </div>
  );
}

export default Home;