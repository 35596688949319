import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import './custombuild.css';
import ResponsiveAppBar from '../../Nav/ResponsiveNav';
import Footer from '../../Footer/Footer';
import Close from '../../images/close.svg';
// SHOWERS
import Duplex from './images/Showers/Duplex.svg';
import Master_Left_Enclosed from './images/Showers/Master_left_enclosed.svg';
import Master_Left_With_Groom from './images/Showers/Master_left_with_groom.svg';
import Master_Right_Enclosed from './images/Showers/Master_right_enclosed.svg';
import Master_Right_With_Groom from './images/Showers/Master_right_withgroom.svg';
import MidBT_Left_Enclosed from './images/Showers/Mid_bigt_left_enclosed.svg';
import MidBT_Left_Open from './images/Showers/Mid_bigt_left_open.svg';
import MidBT_Right_Enclosed from './images/Showers/Mid_bigt_right_enclosed.svg';
import MidBT_Right_Open from './images/Showers/Mid_bigt_right_open.svg';
import MidST_Left_Enclosed from './images/Showers/Mid_smallt_left_enclosed.svg';
import MidST_Left_Open from './images/Showers/Mid_smallt_left_open.svg';
import MidST_Right_Enclosed from './images/Showers/Mid_smallt_right_enclosed.svg';
import MidST_Right_Open from './images/Showers/Mid_smallt_right_open.svg';
import Mini_Left_Enclosed from './images/Showers/Mini_left_enclosed.svg';
import Mini_Left_Open from './images/Showers/Mini_left_open.svg';
import Mini_Right_Enclosed from './images/Showers/Mini_right_enclosed.svg';
import Mini_Right_Open from './images/Showers/Mini_right_open.svg';

// GROOMING STATIONS
import GS_Open_Left from './images/GroomingStations/GS_openleft.svg';
import GS_Open_Right from './images/GroomingStations/GS_openright.svg';

// WET BARS
import Wet from './images/WetBars/Wet.svg';
import Wet_Mini from './images/WetBars/Mini_Wet.svg';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // padding: grid * 2,
  // margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "transparent",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  margin: 20,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "transparent",

  // styles we need to apply on draggables
  ...draggableStyle
});
const getListStyle = isDraggingOver => ({
  background: isDraggingOver && "lightblue",
  // padding: grid,
  // width: 250
});

const CustomBuild = () => {
  let screenWidth = window.innerWidth;
  const [state, setState] = useState([
    // initial dropzone state
    [],
    // initial Showers list
    [
       {
        id: `4-${new Date().getTime()}`,
        content: Mini_Left_Open,
        title: 'Mini Spa Left',
      },
      {
        id: `2-${new Date().getTime()}`,
        content: MidBT_Left_Open,
        title: 'Mid-Day Spa Left',
      },
      {
        id: `1-${new Date().getTime()}`,
        content: Master_Left_Enclosed,
        title: 'Master Spa Left',
      },
      // {
      //   id: `3-${new Date().getTime()}`,
      //   content: MidST_Left_Open,
      //   title: 'Mid Small Spa Left',
      // },
     
      // {
      //   id: `2-${new Date().getTime()}`,
      //   content: Master_Left_With_Groom,
      // },
      
      // {
      //   id: `6-${new Date().getTime()}`,
      //   content: Master_Right_Enclosed,
      //   title: 'Duplex Spa',
      // },
      // {
      //   id: `5-${new Date().getTime()}`,
      //   content: Master_Right_With_Groom,
      // },
      
      // {
      //   id: `8-${new Date().getTime()}`,
      //   content: MidST_Right_Open,
      //   title: 'Mid Small Spa Left',
      // },
      {
        id: `9-${new Date().getTime()}`,
        content: Mini_Right_Open,
        title: 'Mini Spa Right',
      },
      {
        id: `7-${new Date().getTime()}`,
        content: MidBT_Right_Open,
        title: 'Mid-Day Spa Right',
      },
      {
        id: `5-${new Date().getTime()}`,
        content: Master_Right_Enclosed,
        title: 'Master Spa Right',
      },
      {
        id: `0-${new Date().getTime()}`,
        content: Duplex,
        title: 'Duplex Spa',
      },
    ],
    // initial Grooming Stations list
    [
      {
        id: `GS1-${new Date().getTime()}`,
        content: GS_Open_Left,
        title: 'Grooming Station Left',
      },
      {
        id: `GS2-${new Date().getTime()}`,
        content: GS_Open_Right,
        title: 'Grooming Station Right',
      },
    ],
    // initial Wet Bars list
    [
      {
        id: `WB1-${new Date().getTime()}`,
        content: Wet,
        title: 'Wet Bar',
      },
      {
        id: `WB2-${new Date().getTime()}`,
        content: Wet_Mini,
        title: 'Mini Wet Bar',
      },
    ],
  ]);

  function onDragEnd(result) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(state[sInd], source.index, destination.index);
      const newState = [...state];
      newState[sInd] = items;
      setState(newState);
    } else {
      const result = move(state[sInd], state[dInd], source, destination);
      const newState = [...state];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      setState(newState.filter(group => group.length));
    }
  }

  return (
    <>
      <ResponsiveAppBar />
      {screenWidth > 700 ? <div className="custom-build" >
        <div className="build-header">
          <h2>Custom Build</h2>
          <p>Explore different combinations and create new ideas for your custom pet bath.</p>
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          {state.map((el, ind) => {
            console.log('el', el);
            console.log('ind', ind);
            const dropzone = ind === 0 && (
              <Droppable key={ind} droppableId={`${ind}`} direction="horizontal">
                {(provided, snapshot) => (
                  <div className="builder">
                  <div
                    className="drop-zone"
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    {el.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <div
                              className="dropped-item"
                            >
                              <img src={item.content} />
                              {state[0].length > 1 && <button
                                className="remove"
                                type="button"
                                onClick={() => {
                                  const newState = [...state];
                                  newState[ind].splice(index, 1);
                                  setState(
                                    newState.filter(group => group.length)
                                  );
                                }}
                              >
                                <img src={Close} alt="Remove" />
                              </button>}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                  </div>
                )}
              </Droppable>
            );
            const showers = ind === 1 && (
              <Droppable key={ind} droppableId={`${ind}`} direction="horizontal" isDropDisabled>
                {(provided, snapshot) => (
                  <div
                    className="selector"
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    <h3>Showers</h3>
                    <div className="selector-list">
                    {el.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getListItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <p>{item.title}</p>
                            <div
                              className="item"
                              style={{
                                display: "flex",
                                justifyContent: "space-between"
                              }}
                            >
                              <img src={item.content} />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                    </div>
                  </div>
                )}
              </Droppable>
            );
            const groomingStations = ind === 2 && (
              <Droppable key={ind} droppableId={`${ind}`} direction="horizontal" isDropDisabled>
                {(provided, snapshot) => (
                  <div
                    className="selector"
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    <h3>Grooming Stations</h3>
                    <div className="selector-list">
                    {el.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getListItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <p>{item.title}</p>
                            <div
                              className="item"
                              style={{
                                display: "flex",
                                justifyContent: "space-between"
                              }}
                            >
                              <img src={item.content} />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                    </div>
                  </div>
                )}
              </Droppable>
            )
            const wetBars = ind === 3 && (
              <Droppable key={ind} droppableId={`${ind}`} direction="horizontal" isDropDisabled>
                {(provided, snapshot) => (
                  <div
                    className="selector"
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    <h3>Wet Bars</h3>
                    <div className="selector-list">
                    {el.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getListItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <p>{item.title}</p>
                            <div
                              className="item"
                              style={{
                                display: "flex",
                                justifyContent: "space-between"
                              }}
                            >
                              <img src={item.content} />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                    </div>
                  </div>
                )}
              </Droppable>
            )
            return (
              <div>
                {dropzone}
                {showers}
                {groomingStations}
                {wetBars}
              </div>
            )
          })}
        </DragDropContext>
      </div> :
      <div className="small-screen">
        <h3>Please use a tablet or desktop screen to use our interactive Spaw builder</h3>
      </div>}
      <Footer />
    </>
  );
}

export default CustomBuild;