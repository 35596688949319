import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import App from './App';
import About from "./routes/About/About";
import Contact from "./routes/Contact/Contact";
import CustomBuild from "./routes/CustomBuild/BeautifulDnD";
import Spaws from "./routes/Spaws/Spaws";
import Brochure from "./routes/Brochure/Brochure";

import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        {/* <Route path="About" element={<About />} /> */}
        <Route path="Contact" element={<Contact />} />
        <Route path="CustomBuild" element={<CustomBuild />} />
        <Route path="Spaws" element={<Spaws />} />
        <Route path="Brochure" element={<Brochure />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
