import React from 'react';
import ResponsiveAppBar from './Nav/ResponsiveNav';
import Home from './routes/Home/Home';
import Footer from './Footer/Footer';
import './App.css';

const App = () => {
  return (
    <div className="App">
      <div className="App-header">
        <ResponsiveAppBar />
        <Home />
        <Footer />
      </div>
    </div>
  );
}

export default App;
