import React from 'react';
import { saveAs } from 'file-saver';
import ResponsiveAppBar from '../../Nav/ResponsiveNav';
import Footer from '../../Footer/Footer';
import BrochureDL from "../../images/BrochureDL.jpg";
import DL from '../../images/cloud-download.svg';
import './brochure.css';

const Brochure = () => {
  const downloadImage = () => {
    saveAs(BrochureDL, 'BrochureDL.jpg')
  }

  return (
    <div>
      <ResponsiveAppBar />
      <div className="brochure">
      {/* <img src="./Brochure.jpg" alt="brochure" />  */}
      <div className="download" onClick={downloadImage}>
        <img src={DL} alt="download" />
      </div>
      </div>
      <Footer />
    </div>
  );
}

export default Brochure;