import React from 'react';
import { Link } from "react-router-dom";
import Master_Spaw_Combo from "../../images/Master_Spaw_Combo.svg";
import Master_Spaw_Grooming_Station_Tool_Station from "../../images/SpawGroomingTool.svg";
import Mid_Day_Spa_Detatched from "../../images/Mid_Day_Spa_Detatched.svg";
import Duplex_Spaw from "../../images/Duplex_Spaw.svg";
import Mini_Spaw from "../../images/Mini_Spaw.svg";
import ResponsiveAppBar from '../../Nav/ResponsiveNav';
import Footer from '../../Footer/Footer';
import './spaws.css';

const Spaws = () => {
  return (
    <div>
      <ResponsiveAppBar />
       <div className="spaw" id="Spas">
        <h3>Explore our Spaws</h3>
        <h5>Our base models come in a variety of sizes to fit your space and furry
          family member. Add on features to fit your needs such as grooming stations and more.
        </h5>
        <div>
          <img src={Master_Spaw_Combo} alt="Master_Spaw_Combo" />
        </div>
        <div>
          <img src={Master_Spaw_Grooming_Station_Tool_Station} alt="Master_Spaw_Grooming_Station_Tool_Station" />
        </div>
        <div>
          <img src={Mid_Day_Spa_Detatched} alt="Mid_Day_Spa_Detatched" />
        </div>
        <div>
          <img src={Duplex_Spaw} alt="Duplex_Spaw" />
        </div>
        <div>
          <img src={Mini_Spaw} alt="Mini_Spaw" />
        </div>
        {/* <a href="../../images/Brochure.jpg" target="_blank">View our Brochure!</a> */}
        <Link to="/Brochure">
          View our Brochure!
        </Link>
      </div>
      <Footer />
    </div>
  );
}

export default Spaws;