import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-column">
        <h5>Phone</h5>
        <p>(703) 327-8037</p>
      </div>
      <div className="footer-column">
        <h5>Email</h5>
        <p>sheri.doggiesuds@gmail.com</p>
      </div>
      {/* <div className="footer-column">
        <h5>Social Media</h5>
        <p></p>
        <p></p>
        <p></p>
      </div> */}
      <div className="footer-column">
        <h5>Hours</h5>
        <p>9am - 5pm<br></br>Mon - Fri</p>
      </div>
    </div>
  );
}

export default Footer;